import React, { createElement } from "react";
import PortableText from "@sanity/block-content-to-react";
import styled from "styled-components";

import { Maybe, SanityColumn } from "@graphql-types";
import { Container, P } from "@util/standard";
import { urlForImageRef } from "@lib/sanityClient";
import { Button } from "@global";
import { Color } from "@util/types";
import { colors } from "@util/constants";

const Img = styled.img`
  height: 100%;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  margin: 25px 0;
`;

interface Props {
  data: Maybe<SanityColumn> | undefined;
  linkColor?: Color;
}

const BlocksContent = ({ data, linkColor }: Props) => {
  if (data == null) {
    return null;
  }

  const { _rawColumnContent } = data;

  if (_rawColumnContent == null) {
    return null;
  }

  const block = (props: any) => {
    const { style = "normal" } = props.node;
    const tag = style;
    if (/^h\d/.test(tag)) {
      return createElement(tag, { style: { margin: "revert" } }, props.children);
    }
    return <P>{props.children}</P>;
  };

  const ImageRenderer = (props: any) => {
    return (
      <Container>
        <Img src={urlForImageRef(props.node.asset).url() as string} />
      </Container>
    );
  };

  const Link = (props: any) => {
    return (
      <a target={props.node.newwindow && "_blank"} href={props.node.url}>
        <Button
          text={props.node.linktext}
          color={linkColor ?? colors.tomatoSauce}
          margin="30px 0 0 0"
        />
      </a>
    );
  };

  return (
    <PortableText
      blocks={_rawColumnContent}
      serializers={{
        types: { block, imageWithMeta: ImageRenderer, link: Link },
      }}
      imageOptions={{ auto: "format", fit: "fill" }}
      projectId={process.env.SANITY_PROJECT_ID}
      dataset={process.env.GATSBY_NODE_ENV ?? "development"}
    />
  );
};

export default BlocksContent;
