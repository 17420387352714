import React, { CSSProperties } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";

import { Maybe, SanityImage, SanityImageWithMeta } from "@graphql-types";
import { isSanityImageWithMeta } from "@util/types";

const StyledImage = styled(GatsbyImage)<{
  width: string | undefined;
  filter: string | undefined;
}>`
  width: ${props => props.width ?? "100%"};
  ${({ filter }) => filter && `filter: ${filter};`}
`;

const BackgroundImageWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
`;

interface Props {
  data: Maybe<SanityImageWithMeta> | Maybe<SanityImage>;
  staticImage?: string;
  minHeight?: string;
  isBackground?: boolean;
  width?: string;
  height?: string;
  onClick?: () => void;
  objectFit?: CSSProperties["objectFit"];
  margin?: string;
  filter?: CSSProperties["filter"];
}

const Image = (props: Props) => {
  const {
    objectFit,
    staticImage,
    width,
    height,
    minHeight,
    margin,
    isBackground,
    data,
    filter,
    onClick,
  } = props;
  const imageData = data?.asset?.gatsbyImageData;

  if (isBackground && imageData) {
    return (
      <BackgroundImageWrapper>
        <StyledImage
          image={imageData}
          style={{ margin, height: "100%" }}
          width={width}
          filter={filter}
          itemProp="image"
          alt={isSanityImageWithMeta(data) ? (data?.altText as string) : "bg-image"}
          onClick={onClick}
          objectFit={objectFit ?? "cover"}
        />
      </BackgroundImageWrapper>
    );
  }

  if (staticImage) {
    return (
      <img
        src={staticImage}
        width={width ?? "100%"}
        height={height ?? "auto"}
        style={{ minHeight, objectFit }}
        itemProp="image"
      />
    );
  }

  if (imageData) {
    return (
      <StyledImage
        image={imageData}
        style={{ margin: margin }}
        width={width}
        filter={filter}
        itemProp="image"
        alt={isSanityImageWithMeta(data) ? (data?.altText as string) : "gatsby-image"}
        onClick={onClick}
        objectFit={objectFit ?? "contain"}
      />
    );
  }

  return null;
};

export default Image;
