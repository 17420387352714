import React, { ReactNode } from "react";
import { Link as GatsbyLink } from "gatsby";
import styled from "styled-components";

import { A } from "@util/standard";
import { Maybe, Scalars } from "@graphql-types";

interface Props {
  linktext?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  internallink?: Maybe<Scalars["Boolean"]>;
  newwindow?: Maybe<Scalars["Boolean"]>;
  className?: string;
  children?: ReactNode;
}

const StyledGatsbyLink = styled(GatsbyLink)`
  font-family: "roboto";
  user-select: none;
`;

const Link = ({ children, linktext, url, internallink, newwindow, className = "" }: Props) => {
  if (internallink) {
    const to = url == null ? "/" : url === "/" ? "/" : `/${url}`;
    return (
      <StyledGatsbyLink activeClassName="active" className={className} to={to}>
        {children ?? linktext}
      </StyledGatsbyLink>
    );
  }

  return (
    <A
      href={url ?? "/"}
      className={className}
      target={newwindow ? "_blank" : ""}
      rel={newwindow ? "noreferrer" : ""}
    >
      {children ?? linktext}
    </A>
  );
};

export default Link;
