import React from "react";
import styled from "styled-components";

import { Container, Separator } from "@util/standard";
import { BurgerBadge, Image } from "@global";
import { SanityRecipes } from "@graphql-types";
import {
  colors,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  IPAD_BREAKPOINT,
  assets,
  RECIPES_SITE,
} from "@util/constants";
import { formatTimeString, minsToHrsMins } from "@util/helper";

const Wrapper = styled(Container)`
  flex-direction: column;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

export const ImageWrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

const Title = styled.h4`
  font-size: 18px;
  font-weight: 500;
  white-space: normal;
  line-height: 21px;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 16px;
    line-height: 16px;
    margin-top: 5px;
  }
`;

const ImageContainer = styled(Container)`
  height: 300px;
  overflow: hidden;
  position: relative;
  img {
    border-radius: 10px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 200px;
  }

  @media only screen and (max-width: 1350px) {
    height: 225px;
  }
`;

const Author = styled.p`
  margin: 0 0 15px 0;
  color: ${colors.lightBlue};
  white-space: normal;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 50%;
  }

  @media only screen and (max-width: ${IPAD_BREAKPOINT}px) {
    width: 60%;
    margin-bottom: 15px;
  }
`;

const CookTimeContainer = styled(Container)`
  margin-top: 10px;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const CookTime = styled.p`
  color: ${colors.lightBlue};
  white-space: normal;
  margin: 0px 3px 0px 0px;
`;

export interface Props {
  data: SanityRecipes;
}

const Preview = ({ data }: Props) => {
  if (data == null) return null;

  const { title, prepTime, cookTime, author, featureImages, slug } = data;

  const PreviewContent = () => {
    const { hours: cookHours, minutes: cookMinutes } = minsToHrsMins(parseInt(cookTime!, 10));
    const { hours: prepHours, minutes: prepMinutes } = minsToHrsMins(parseInt(prepTime!, 10));
    const formattedCookTime = formatTimeString(cookHours, cookMinutes);
    const formattedPrepTime = formatTimeString(prepHours, prepMinutes);

    return (
      <Container flexDirection="column" flex={1}>
        <CookTimeContainer>
          <CookTime>
            {!formattedPrepTime.includes("NaN") ? formattedPrepTime : prepTime} prep |
          </CookTime>
          <CookTime>
            {!formattedCookTime.includes("NaN") ? formattedCookTime : cookTime} cook
          </CookTime>
        </CookTimeContainer>

        <Title>{title}</Title>
        {author && (
          <>
            <Separator width="20px" color="darkGreen" opacity={0.3} />
            <Author>{author.name}</Author>
          </>
        )}
      </Container>
    );
  };

  return (
    <a target="_blank" href={`${RECIPES_SITE}/recipes/${slug?.current}`}>
      <Wrapper>
        <ImageContainer>
          {featureImages && featureImages[0] ? (
            <Image data={featureImages[0]} objectFit="cover" />
          ) : (
            <img src={assets.defaultRecipe} alt="default-preview-image" />
          )}
          <BurgerBadge positioning={{ left: "10px", bottom: "10px" }} />
        </ImageContainer>

        <PreviewContent />
      </Wrapper>
    </a>
  );
};

export default Preview;
