import React from "react";
import styled from "styled-components";

import { Maybe, SanityColumn } from "@graphql-types";
import { Container } from "@util/standard";
import { BlocksContent } from "@global";
import { colorsRGB, TABLET_BREAKPOINT, MOBILE_BREAKPOINT } from "@util/constants";

interface Props {
  data: Maybe<SanityColumn> | undefined;
}

const TextWrapper = styled(Container)`
  width: 90%;
  margin: 30px auto 50px auto;
  text-align: center;
  div {
    width: 100%;
  }
  max-width: 800px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 90%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 80%;
  }
`;

function CtaBanner({ data }: Props) {
  if (data == null) return null;

  return (
    <Container backgroundRGBColor={colorsRGB.tan()} borderRadius={20} margin="20px auto">
      <TextWrapper>
        <BlocksContent data={data} />
      </TextWrapper>
    </Container>
  );
}

export default CtaBanner;
