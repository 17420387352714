import { Dispatch, SetStateAction } from "react";
import { assets, buttonStyle, colors, colorsRGB, fontWeights } from "./constants";

import { SanityImage, SanityImageWithMeta } from "@graphql-types";

export type Asset = keyof typeof assets;
export type Color = keyof typeof colors;
export type ColorRGB = keyof typeof colorsRGB;
export type ButtonTheme = keyof typeof buttonStyle;
export type ButtonType = typeof buttonStyle;
export type FontWeights = keyof typeof fontWeights;
export type AssetType = typeof assets;

export type SetRegion = Dispatch<
  SetStateAction<{
    iban: string;
    langCode: string;
  }>
>;

export type AnimationDirection = {
  to: {
    transform?: string;
    opacity?: number;
  };
  from: {
    transform?: string;
    opacity?: number;
  };
};

export function isSanityImage(data: any): data is SanityImage {
  return data._type === "image";
}

export interface ScreenWidth {
  isTabletWidth: boolean;
  isMobileWidth: boolean;
  isLaptopWidth: boolean;
}

export function isSanityImageWithMeta(data: any): data is SanityImageWithMeta {
  return data._type === "imageWithMeta";
}

export interface AbsoluteProps {
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
  zIndex?: number;
}
