import React from "react";
import styled from "styled-components";
import moment from "moment";

import { Maybe, SanityColour, SanityRecipePreview, SanityRecipes } from "@graphql-types";
import { Container, GridContainer } from "@util/standard";
import Preview from "./preview";
import { colors, RECIPES_SITE, TABLET_BREAKPOINT } from "@util/constants";
import { Link } from "@global";

const Title = styled.h3<{ color: string }>`
  font-size: 25px;
  font-family: "roboto slab";
  font-weight: 400;
  margin: 20px 0;
  ${({ color }) => color && `color: ${color};`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 20px;
    line-height: 26px;
  }
`;

const LinkText = styled.p`
  color: ${colors.avocado};
  text-decoration: underline;

  :hover {
    color: ${colors.darkGreen};
  }
`;

interface Props {
  data: Maybe<SanityRecipePreview> | undefined;
  recipes: SanityRecipes[];
  baseColour: Maybe<String> | undefined;
}

const PreviewListing = ({ baseColour, data, recipes }: Props) => {
  if (data == null) return null;
  const { previews, title, link } = data;
  const color = baseColour;
  const dayOfMonth = moment().date();
  const recipesToSlice = previews && previews.length > 0 ? previews : recipes;
  const recipesToShow = recipesToSlice.slice(dayOfMonth, dayOfMonth + 16);

  return (
    <>
      <Container alignItems="center" justifyContent="space-between">
        <Title color={color as string}>{title}</Title>
        {link && (
          <Link
            url={`${RECIPES_SITE}/${link.url}`}
            internallink={link.internallink}
            newwindow={link.newwindow}
          >
            <LinkText>{link?.linktext}</LinkText>
          </Link>
        )}
      </Container>
      <GridContainer repeat={4} rowGap={50} columnGap={20}>
        {recipes.slice(0, 16).map(recipe => {
          if (recipe == null) return null;

          return <Preview key={recipe._id} data={recipe} />;
        })}
      </GridContainer>
    </>
  );
};

export default PreviewListing;
