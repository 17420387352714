export const isBrowser = () => typeof window !== "undefined";

export function arraysEquality(array1: any[], array2: any[]) {
  return array1.length === array2.length && array1.every((value, index) => value === array2[index]);
}

export function objectEquality(object1: any, object2: any) {
  return Object.keys(object1).every(key => object1[key] === object2[key]);
}

export function splitArrayIntoChunks(array: any[], split: number) {
  const chunks = array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / split);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return chunks;
}

export function minsToHrsMins(mins: number) {
  var hours = Math.trunc(mins / 60);
  var minutes = mins % 60;
  return { hours, minutes };
}

export function formatTimeString(hours: number, minutes: number) {
  if (hours) {
    if (minutes > 0) {
      return `${hours} hrs ${minutes} mins`;
    }
    return `${hours} hrs`;
  }

  return `${minutes} mins`;
}
