import styled, { createGlobalStyle } from "styled-components";

import { MOBILE_BREAKPOINT, SMALL_LAPTOP_BREAKPOINT } from "@util/constants";
import { fontSizes, fontWeights, PRIMARY_COLOR } from "@util/constants";

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: roboto, sans-serif;
    font-size: ${fontSizes.p.default}px;
    font-weight: ${fontWeights.normal};
    color: ${PRIMARY_COLOR};
  }

  h1, h2 {
    font-family: cortado, sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  h1 {
    line-height: 1em;
    font-size: ${fontSizes.h1.default}px;
  }
  h2 {
    font-size: ${fontSizes.h2.default}px;
    margin: 30px 0;
  }
  h3 {
    font-size: ${fontSizes.h3.default}px;
    margin: 15px 0;
  }
  h4 {
    font-size: ${fontSizes.h4.default}px;
    margin: 15px 0;
  }
 
  a {
    user-select: none;
    text-decoration: none;
    white-space: nowrap;
    color: inherit;

    &:hover {
      filter: brightness(0.9);
    }
  }

  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    h1 {
      font-size: ${fontSizes.h1.tablet}px;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    body {
    font-size: ${fontSizes.p.mobile}px;
    }
    h1 {
      font-size: ${fontSizes.h1.mobile}px;
    }
    h2 {
      font-size: ${fontSizes.h2.mobile}px;
    }
    h3 {
      font-size: ${fontSizes.h3.mobile}px;
    }
    h4 {
      font-size: ${fontSizes.h4.mobile}px;
    }
  }
`;

export const Main = styled.main<{}>`
  width: 90%;
  margin: auto;
`;
