import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import { Link, Image, BurgerBadge } from "@global";
import { Query, Maybe } from "@graphql-types";
import { colors, RECIPES_SITE } from "@util/constants";
import { Container } from "@util/standard";
import {
  TABLET_BREAKPOINT,
  MOBILE_BREAKPOINT,
  SMALLER_MOBILE,
  SMALL_LAPTOP_BREAKPOINT,
} from "@util/constants";

const BottomFooter = styled(Container)`
  justify-content: space-between;
  padding: 30px 0;
  width: 90%;
  margin: auto;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    height: auto;
    width: 100%;
  }
`;

const Wrapper = styled(Container)<{ bg: Maybe<string> | undefined }>`
  margin-top: 50px;
  background-color: transparent;
  flex-direction: column;
  background-color: ${props => props.bg ?? colors.darkGreen};
`;

const InnerWrapper = styled(Container)`
  width: 90%;
  flex-direction: column;
  justify-content: flex-end;
  p {
    color: ${colors.white};
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    align-items: center;
    margin: auto;
  }
`;

const Links = styled(Container)`
  flex-direction: row;
  padding-top: 10px;
  background-color: transparent;

  p,
  a {
    margin: 0;
    color: ${colors.white};
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
    display: none;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 400px;
  }

  @media only screen and (max-width: ${SMALLER_MOBILE}px) {
    width: 300px;
  }
`;

const MobileLinks = styled(Container)`
  display: none;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
    display: flex !important;
    p,
    a {
      margin: 0;
      color: ${colors.white};
    }
  }
`;

const LinksDivider = styled.div<{ index?: number }>`
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ index }) => index === 3 && `display: none;`}
    padding: 0 15px;
  }

  @media only screen and (max-width: ${SMALLER_MOBILE}px) {
    padding: 0 8px;
  }
`;

const Divider = styled.div`
  padding-left: 13px;
  padding-right: 10px;
  text-align: center;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 10px;
  }
`;

const SocialContainer = styled(Container)`
  align-items: flex-end;
  img {
    filter: brightness(0) invert(1);
  }

  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    display: none;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: flex;
    align-self: center;
    justify-content: center;
    padding: 30px 0 20px 0;
    width: 50%;
  }
`;

const BadgeContainer = styled(Container)`
  position: relative;
  width: 130px;
  height: 100px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    div {
      left: calc(50% - 50px);
    }
  }
`;

const Footer = () => {
  const { sanityFooter, sanityBurgerThursdays }: Query = useStaticQuery(graphql`
    {
      sanityFooter {
        logo {
          asset {
            gatsbyImageData(layout: FIXED, height: 30, width: 190, formats: [AUTO, WEBP])
          }
        }
        menuItems {
          link {
            ...sanityLink
          }
          _key
        }
        mobileMenuItems {
          link {
            ...sanityLink
          }
          _key
        }
        socialLinks {
          socialLinks {
            linkTo
            _key
            socialIcon {
              asset {
                gatsbyImageData(layout: FIXED, width: 22, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
      sanityBurgerThursdays {
        baseColour
      }
    }
  `);

  if (sanityFooter == null) return null;

  const logo = sanityFooter.logo;
  const menuItemsLength = sanityFooter.menuItems?.length;
  const mobileMenuItemsLength = sanityFooter.mobileMenuItems?.length;
  const color = sanityBurgerThursdays?.baseColour;

  return (
    <Wrapper bg={color}>
      <BottomFooter>
        <BadgeContainer>
          <BurgerBadge />
        </BadgeContainer>
        <InnerWrapper>
          <p>Brought to you by</p>
          {logo && (
            <a target="_blank" href={RECIPES_SITE}>
              <Image data={logo} filter="brightness(0) invert(1)" />
            </a>
          )}
          {/* Desktop links */}
          <Links>
            {sanityFooter &&
              sanityFooter.menuItems?.map((menuItem, index) => {
                if (menuItem == null) return null;
                const { link } = menuItem;

                return (
                  <Container key={menuItem._key}>
                    <Link
                      newwindow
                      url={`${RECIPES_SITE}/${link?.url}`}
                      linktext={link?.linktext}
                    />
                    {menuItemsLength !== index + 1 && (
                      <LinksDivider index={index}>
                        <p>|</p>
                      </LinksDivider>
                    )}
                  </Container>
                );
              })}
          </Links>
          {/* Mobile Links */}
          <MobileLinks>
            {sanityFooter &&
              sanityFooter.mobileMenuItems?.map((menuItem, index) => {
                if (menuItem == null) return null;
                const { link } = menuItem;

                return (
                  <Container key={menuItem._key}>
                    <Link
                      newwindow
                      url={`${RECIPES_SITE}/${link?.url}`}
                      linktext={link?.linktext}
                    />
                    {mobileMenuItemsLength !== index + 1 && (
                      <LinksDivider index={index}>
                        <p>|</p>
                      </LinksDivider>
                    )}
                  </Container>
                );
              })}
          </MobileLinks>
        </InnerWrapper>
        <SocialContainer>
          {sanityFooter &&
            sanityFooter.socialLinks?.socialLinks?.map(socialLink => {
              if (socialLink == null) return null;
              const icon = socialLink.socialIcon;

              return (
                <Divider key={socialLink._key}>
                  <Link newwindow url={socialLink.linkTo}>
                    {icon && <Image data={icon} />}
                  </Link>
                </Divider>
              );
            })}
        </SocialContainer>
      </BottomFooter>
    </Wrapper>
  );
};

export default Footer;
