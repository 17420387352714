import React, { ReactNode } from "react";

import Footer from "./footer";
import { GlobalStyle, Main } from "@styles/globalStyles";
import { Helmet } from "react-helmet";
interface Props {
  children: ReactNode;
}

function Layout(props: Props) {
  const { children } = props;

  return (
    <div>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap"
          rel="stylesheet"
        />
        <link rel="stylesheet" href="https://use.typekit.net/eiw2mnq.css" />
      </Helmet>
      <GlobalStyle />
      <Main>{children}</Main>
      <Footer />
    </div>
  );
}

export default Layout;
