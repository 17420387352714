import React from "react";
import { Widget } from "@typeform/embed-react";
import styled from "styled-components";

import { TABLET_BREAKPOINT } from "@util/constants";
import { Container } from "@util/standard";

const Wrapper = styled(Container)`
  width: 60%;
  margin: 0 0 0 20px;
  border-radius: 20px;
  overflow: hidden;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 20px 0 0 0;
    width: 100%;
    min-height: 400px;
  }
`;

interface Props {
  formId: string;
}

export default function TypeForm({ formId }: Props) {
  return (
    <Wrapper>
      <Widget id={formId} style={{ width: "100%" }} />
    </Wrapper>
  );
}
