export const BUTTON_HEIGHT = 46;
export const HEADER_HEIGHT = 84;

// screen sizes
export const SMALLER_MOBILE = 400;
export const MOBILE_BREAKPOINT = 600;
export const IPAD_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1000;
export const IPAD_PRO_BREAKPOINT = 1025;
export const SMALL_LAPTOP_BREAKPOINT = 1280;
export const LAPTOP_BREAKPOINT = 1441;
export const TWENTY_INCH_SCREEN = 1600;
export const IMAC_BREAKPOINT = 1921;
export const DEFAULT_OFFSET = 120;
export const MOBILE_OFFSET = 60;

export const RECIPES_SITE = "https://www.recipes.co.nz";
export const RECIPES_SITE_BURGERS = "https://www.recipes.co.nz/burgers";


export const colors = {
  transparent: "transparent",
  white: "#fff",
  black: "#000",
  flightBlue: "#24468E",
  darkGreen: "#314A37",
  avocado: "#B2BA48",
  tomatoSauce: "#be1e2d",
  lightBlue: "#B1CCD1",
};

export const fontWeights = {
  normal: 400,
  semibold: 600,
  bold: 700,
  extrabold: 800,
};

export const PRIMARY_COLOR = colors.darkGreen;
export const SECONDARY_COLOR = colors.black;

export const fontSizes = {
  p: { default: 14, mobile: 12 },
  h1: { default: 100, mobile: 60, tablet: 80 },
  h2: { default: 80, mobile: 40 },
  h3: { default: 30, mobile: 15 },
  h4: { default: 20, mobile: 14 },
  span: { default: 16, mobile: 12 },
};

export const colorsRGB = {
  flightBlue: (opacity?: number) => `rgba(0, 28, 168, ${opacity ?? "0.4"})`,
  black: (opacity?: number) => `rgba(0, 0, 0, ${opacity ?? "0.2"})`,
  white: (opacity?: number) => `rgba(255, 255, 255, ${opacity ?? "0.3"})`,
  tan: (opacity?: number) => `rgba(201,165,132, ${opacity ?? "0.2"})`,
};

export const pages = {
  home: "/",
  aboutUs: "/about-us",
  contactUs: "/contact-us",
  search: "/search",
  notFound: "/404",
};

// define paths to asset
export const assets = {
  logo: require("@static/assets/logo.svg").default,
  badgeWhite: require("@static/assets/badgeWhite.svg").default,
  badgeRed: require("@static/assets/badgeRed.svg").default,
  defaultRecipe: require("@static/assets/default_recipe.png").default,
};

export const buttonStyle = {
  base: {
    bg: `${colors.darkGreen}`,
    text: `${colors.white}`,
    border: `${colors.darkGreen}`,
    hoverBg: `${colors.avocado}`,
    hoverBorder: `${colors.avocado}`,
    hoverText: `${colors.white}`,
  },
  white: {
    bg: `${colors.transparent}`,
    text: `${colors.white}`,
    border: `${colors.white}`,
    hoverBg: `${colors.flightBlue}`,
    hoverBorder: `${colors.flightBlue}`,
    hoverText: `${colors.white}`,
  },
  whiteInverse: {
    bg: `${colors.transparent}`,
    text: `${colors.white}`,
    border: `${colors.white}`,
    hoverBg: `${colors.white}`,
    hoverBorder: `${colors.flightBlue}`,
    hoverText: `${colors.flightBlue}`,
  },
};
