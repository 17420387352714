import React from "react";
import styled from "styled-components";

import { AbsoluteWrapper } from "@util/standard";
import { AbsoluteProps } from "@util/types";
import { assets, MOBILE_BREAKPOINT } from "@util/constants";

const Wrapper = styled(AbsoluteWrapper)`
  z-index: ${props => props.zIndex ?? 1};
`;

const Img = styled.img<{ mobileWidth?: string }>`
  transform: rotate(350deg);

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`}
  }
`;

interface Props {
  badgeColor?: "red" | "white";
  positioning?: AbsoluteProps;
  width?: string;
  mobileWidth?: string;
  height?: string;
}

export default function BurgerBadge({
  badgeColor = "white",
  width = "100px",
  mobileWidth,
  height = "auto",
  positioning,
}: Props) {
  const image = () => {
    if (badgeColor === "red") return assets.badgeRed;
    return assets.badgeWhite;
  };

  return (
    <Wrapper {...positioning}>
      <Img
        src={image()}
        width={width}
        height={height}
        alt="burger-thursday-badge"
        mobileWidth={mobileWidth}
      />
    </Wrapper>
  );
}
